import logo from './logo-planner-3.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Card} from 'react-bootstrap';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Card style={{ width: '18rem' }}>
        <Card.Img variant="top" src={logo} />
        <Card.Body>
          <Card.Title>Tracking</Card.Title>
          <Card.Text>
            XXXX.XXXXXX.XXXXXXXXX<br/>
            PO: XX-999999 <br/>
            Item: 99<br/>
            Date: 99/99/99<br/>
            </Card.Text>
        </Card.Body>
      </Card>       
      </header>      
    </div>
  );
}

export default App;
